<template>
  <div id="BrandDetail">
    <Header></Header>
    <div class="position_box">
      <div class="center_position">
        <img
          class="backImg"
          @click="backHome"
          src="../../assets/img/back_home.png"
          alt=""
        />
        <div class="home" @click="backHome">首页</div>
        <div class="current">当前位置：</div>
        <div class="home1" @click="backHome">首页></div>
        <div class="video_center" @click="backvideolist">品牌动态列表></div>
        <div class="video_detail">{{ videoDetail.title }}</div>
      </div>
    </div>
    <div class="title">{{ videoDetail.title }}</div>
    <div class="content_Box" v-html="videoDetail.text"></div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import { getBrandNewsInfo } from "../../http/api";
export default {
  name: "",
  props: {},
  components: {
    Header,
  },
  data() {
    return {
      videoDetail: {},
    };
  },
  methods: {
    backvideolist() {
      this.$router.go(-1);
    },
    backHome() {
      this.$router.push("/");
    },
  },
  mounted() {
    getBrandNewsInfo({
      id: this.$route.query.id,
    }).then((res) => {
      res.data.text.replace(/<img/g, '<img style="max-width:100%;"');
      this.videoDetail = res.data;
    });
  },
};
</script>

<style scoped lang='scss'>
#BrandDetail {
  width: 100%;
  .position_box {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #e5e5e5;
    .center_position {
      width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      .backImg {
        width: 30px;
        height: 30px;
        display: block;
        cursor: pointer;
      }
      .home {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        margin: 0 30px 0 20px;
        cursor: pointer;
      }
      .current {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .home1 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #666666;
        cursor: pointer;
      }
      .video_center {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        cursor: pointer;
        color: #666666;
      }
      .video_detail {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 25px;
        color: #225928;
      }
    }
  }
  .title {
    text-align: center;
    font-size: 50px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #005a3f;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .content_Box {
    width: 1200px;
    margin: 0 auto;
    min-height: 430px;
    margin-bottom: 120px;
  }
}
</style>